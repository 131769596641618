import React from 'react';
import { useQueryParam } from 'use-query-params';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import Checkout from '../../../components/Store/Checkout';
import { NUTRITION_PLAN } from '../../../enums/store.enum';

const stage = process.env.GATSBY_STAGE;

const CheckoutDetails = ({ quotes }) => {
    const planData = NUTRITION_PLAN;
    const [couponCode] = useQueryParam('coupon');
    const [noheader] = useQueryParam('noheader');
    const [four_WP] = useQueryParam('4-week-physique');

    if (!planData) {
        return (
            <Layout noFooter>
                <SEO
                    title="Checkout - Nutrition Hub | J Clarke Fitness"
                    description="My brand new Transformation Program is finally here and it is bigger and better than ever before!"
                />
            </Layout>
        );
    }

    const priceCents = planData.price * 100;
    const checkoutData = {
        planCode: planData.plan[stage as string],
        name: four_WP ? '4 Week Physique' : planData.description,
        currentPrice: priceCents,
        price: priceCents,
        setupFees: 0,
        recurring: false,
        fourWeekPhysique: four_WP,
        currency: '€',
    };

    return (
        <Layout noFooter noHeader={!!noheader}>
            <SEO
                title="Checkout - Nutrition Hub | J Clarke Fitness"
                description="My brand new Transformation Program is finally here and it is bigger and better than ever before!"
            />
            <Checkout
                program="nutrition-hub"
                checkoutData={checkoutData}
                quotes={[]}
                predefinedCouponCode={couponCode}
            />
        </Layout>
    );
};

export default CheckoutDetails;
